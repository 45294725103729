import React from 'react';
import './ButtonSecond.css';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsAuth } from '../../store/slices/Auth/AuthSlice';


function ButtonSecond({
   countsBtn,
   isShow,
   txt,
   path,
   minWidth = '',
   maxWidth = '',
   onClick = () => {},
   background = '',
   color = '',
   boxShadow = '',
   fontSize='',
   newClass= '',
}) {
   const lang = localStorage.getItem('lang');
   const navigate = useNavigate();
   const { tickets} = useSelector(
      (state) => state.museumTicket,
   );
   const isAuth = useSelector(getIsAuth);


  const { t, i18n } = useTranslation();

  if (path) {
    return (
      <NavLink to={`/${lang}/${path}`}>
        <button className="btn_2 draw-border_2">{t('buttons.' + txt)}</button>
      </NavLink>
    );
  }

  const totalPrice = tickets.reduce((acum, item) => {
   return acum + item.price * item.count ?? 0;
}, 0);

const isDisabled = () => {
   if (!countsBtn) {
      return false; // Not disabled if countsBtn is not true
   }
   if (isShow === 'cart' && totalPrice > 0 && isAuth) {
      return false; // Not disabled when 'cart' is true, totalPrice > 0, and isAuth is true
   }
   if (isShow !== 'cart' && totalPrice > 0) {
      return false; // Not disabled when 'cart' is not true and totalPrice > 0
   }
   return true; // Disabled in all other cases
};
   return (
      <button
         disabled={isDisabled()}
         onClick={onClick}
         style={{
            minWidth: minWidth,
            maxWidth: maxWidth,
            background: background,
            color: color,
            boxShadow: boxShadow,
            fontSize: fontSize
         }}
         className={`btn_2 draw-border_2 ${newClass}`}>
         {t('buttons.' + txt)}
      </button>
   );

}

export default ButtonSecond;
